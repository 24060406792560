<template>
    <CustomLinkVue :link="link" :isCustom="isCustom" @click="(data) => $emit('click',data)">
      <div class="bg-white transition-all flex flex-col items-center justify-center border rounded-md shadow-sm hover:drop-shadow min-h-[140px]">
        <div class="min-h-[30x] mt-4 mb-2 px-2">
          <img :src="image" class="max-h-[40px] max-w-full object-contain" alt="...">
        </div>
        <div class="">
          <p class="text-gray-900 no-underline text-[10pt] line-clamp-2">{{ title }}</p>
        </div>
      </div>
    </CustomLinkVue>
  </template>
  
  <script>
    import CustomLinkVue from './CustomLink.vue';
    export default {
      components: {
        CustomLinkVue
      },
      props: {
        image: String,
        title: String,
        link: String,
        isCustom: Boolean,
      }
    }
  </script>
