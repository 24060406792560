import axios from "axios";
import { isTokenExpired } from './token_manager';
import { useRouter } from 'vue-router'
import { globalRouter } from '../router/globalRouter';

const backendURL = process.env.VUE_APP_BACKEND_URL
console.log('Backend URL', backendURL)

export const client = {
    'api': axios.create({
        baseURL: backendURL,
        timeout: 1000,
        headers: {
            "Content-Type": "application/json",
        }
    }),
    'bearer': undefined,
    'id': undefined,
    router: useRouter(),
    listers: [],
    dispatch(id) {
        this.listers.forEach((callback) => {
            localStorage.setItem('localUserID', id)
            callback(id)
            client.id = id
        })
    }
}

client.api.interceptors.request.use(
    async (config) => {
        const refreshToken = localStorage.getItem('refreshToken');
        let bearerToken = client.bearer;
        console.log('bearer nao client',bearerToken)
        if(!bearerToken || isTokenExpired(bearerToken)) {
            console.log('entrou no iff')
            if (refreshToken) {
                try {       
                    const {data} = await axios.post(backendURL+"/api/token/refresh/", {
                        refresh: refreshToken,
                    })
                    console.log('resp:')
                    console.log(data)
                    client.bearer = data?.access
                    bearerToken = data?.access
                    // localStorage.setItem('refreshToken', response.data.refresh)

                } catch (error) {
                    return Promise.reject(error)
                }
               
            } else {
                return Promise.reject({ status: 401, message: 'Deu ruim' })
            }
        }
        console.log('url e bearer e bearer')
        console.log(config.url, client.bearer, bearerToken)
        console.log(client)
        if (bearerToken) {
            console.log('!!!!!!!!!setando o bearer!!!!!!!!!')
            config.headers['Authorization'] = `Bearer ${bearerToken}`;
        }
        console.log('config')
        console.log(config)
        return config;
    },
    error => Promise.reject(error)
);

client.api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // Tratar erros na resposta
        
        const status = error.status ?? error.response?.status
        console.log(status)
        if (status === 401 || status === 403) {
            console.log('resp')
            globalRouter.router?.push("/login");
        }
        return Promise.reject(error);
    }
  );
export async function revokeToken(refresh) {
    return axios.post(backendURL+"api/token/blacklist/", {
        refresh
    })
}

client.api.getToken = async function (credential, clientId) {
    const response = await axios.post(
         backendURL+"sso-token/",
        {
            credential: credential,
            clientId: clientId,
        },
    )
    localStorage.setItem('refreshToken', response.data['refresh']);
    client.bearer = response.data['access']
    client.dispatch(response.data['user_id'])
}

client.retrieveValidToken = async function () {
    return new Promise((resolve, reject) => {
        let access = client.bearer;
        if(access && !isTokenExpired(access)) return resolve(access);
    
        const refresh = localStorage.getItem('refreshToken');
        if (refresh) {
            try {       
                axios.post(backendURL+"/api/token/refresh/", { refresh }).then(data => {
                    client.bearer = data?.access
                    return resolve(data?.access)
                })
            } catch (error) {
                return reject(error);
            }
        } else {
            return reject(Error("Couldn't retrieve a valid token, the user must login again."))
        }
    })
}

client.api.getAppsUser = async function () {
    return new Promise((resolve, reject) => {
        const url = 'api/apps/'
        this.request({
            method: "get",
            url: url
        }).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    })
}