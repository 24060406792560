<template>
   <img class="hidden md:block w-[220px] pr-[10px]" src="../assets/logo-match.2053fbcf.svg" />
    <svg
      class="md:hidden"
      width="35"
      height="26"
      viewBox="0 0 40 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="10"
        height="10"
        fill="black"
        x="0"
        y="0"
      />
      <rect
        width="10"
        height="10"
        y="0"
        x="20"
        fill="black"
      />
      <rect
        width="10"
        height="10"
        y="20"
        x="20"
        fill="black"
      

      />
      <rect
        width="10"
        height="10"
        y="20"
        x="0"
        fill="black"
      />
      <rect
        width="10"
        height="10"
        className="transition-transform ease-in"
        x="30"
        y="10"
        fill= "black"
      />
      
    </svg>
</template>

