import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '../components/LoginPage.vue'
import Dashboard from '../components/DashBoardPage.vue'
import NotFoundPage from '../components/NotFoundPage.vue'

const routes = [
  { path: '/login', component: LoginPage, name: 'login' },
  { path: '/dashboard', component: Dashboard, name: 'dashboard' },
  { path: '/:pathMatch(.*)', component: NotFoundPage, name: 'NotFound' }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
