const MILLI_MULTIPLYER = 1000;
const TOKEN_GRACE_PERIOD = 2000;

// returns timestamp
const getTokenExpiry = (token) => {
  const tokenParts = token.split(".");
  // const decodedTokenData = Buffer.from(tokenParts[1] || "", "base64").toString();
  const decodedTokenData = atob(tokenParts[1])
  const parsedTokenData = JSON.parse(decodedTokenData);

  // because api returns seconds, but Date.now() works in millis
  return parsedTokenData.exp * MILLI_MULTIPLYER || 0;
};

export const isTokenExpired = (token) => {
  // we'll assume a generous time of 2 seconds for api to
  // process our request
  return getTokenExpiry(token) - TOKEN_GRACE_PERIOD <= Date.now();
};