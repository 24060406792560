<script setup>
  import { defineProps, defineEmits, ref } from 'vue';

  const open = ref(false)

  const props = defineProps({
    profile: {
      name: String,
      picture: String,
      hasProfile: Boolean,
      bu: String
    }
  })

  const emit = defineEmits(['logout'])
</script>
<template>
    <div class="flex items-center gap-2 border rounded p-1 cursor-pointer relative" v-if="props.profile?.hasProfile" @click="() => {open = !open}">
      <img class="w-10 h-10 rounded-full" :src="props.profile?.picture" referrerpolicy="no-referrer" />
      <div>
        <p class="m-0 text-[9pt] text-center">{{ props.profile?.name }}</p>
        <p class="m-0 text-[9pt] text-gray-400 text-center">{{ props.profile?.bu }}</p>
      </div>
      <dialog :open="open" class="bg-white py-2 top-full shadow mt-2 border rounded w-full">
        <button @click="() => {emit('logout')}" class="hover:bg-slate-100 w-full text-[10pt] p-1">Log out</button>
      </dialog>
    </div>
</template>
