import { createApp } from 'vue'
import App from './App.vue'
import vue3GoogleLogin from 'vue3-google-login'
import router from './router'
import './index.css'
import 'bootstrap/dist/css/bootstrap.css'

const app = createApp(App)

console.log('ENV VAR')
console.log(process.env.VUE_APP_SSO_CLIENT_ID)

app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_SSO_CLIENT_ID
})

app.use(router)

app.mount('#app')
