<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<script>
import { RouterView } from 'vue-router'
import { useRouter } from 'vue-router'
import { globalRouter } from './router/globalRouter';


export default {
  components: {
    RouterView
  },
  mounted: function(){
    document.title = 'HUB - Match.mt';
    const router = useRouter()
    router.replace('/dashboard')
    globalRouter.router = router;
  }
}
</script>

<style>
/* Adicione estilos conforme necessário */
</style>
