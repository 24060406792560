<script setup>
import { decodeCredential } from 'vue3-google-login'
import { useRouter } from 'vue-router'
import { client } from '@/utils/api.js'
import MatchStampVue from './MatchStamp.vue';

const router = useRouter()
const idclient = process.env.VUE_APP_SSO_CLIENT_ID

function redirect(){
  router.push('/dashboard')
}

const callback = async (response) => {
  let userData = {}
  if (Object.prototype.hasOwnProperty.call(response, 'credential')){
    userData = decodeCredential(response.credential)
    localStorage.setItem('userData', JSON.stringify(userData));
    console.log('credential', response.credential)
    await client.api.getToken(response.credential,idclient)
    console.log('client api')
    console.log(client)

    redirect()
  }

}

</script>

<template>
  <div className="min-h-screen bg-no-repeat bg-cover bg-center bg-gradient-to-br bg-black-patterns from-gray-700 to-gray-500 overflow-hidden">
      <div className="flex justify-end">
        <div className="bg-white min-h-screen w-full md:w-1/2 flex flex-col gap-10 justify-center items-center">
            <MatchStampVue />
            <div className="w-[233px]">
              <div>
                <span className="text-sm text-gray-900 text-center">
                  Seja bem-vindo de volta :)
                </span>
                <h1 className="text-2xl font-bold pt-4">Login</h1>
              </div>
              <div className="flex justify-start h-[45px]">
                <GoogleLogin :callback="callback" prompt auto-login/>
              </div>
                <p className="text-sm text-red-500 pt-2"></p>
          </div>
        </div>
      </div>
    </div>
</template>

