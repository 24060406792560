<script setup>

  import { defineProps, defineEmits } from 'vue';

  const props = defineProps({ isCustom: Boolean, link: String })
  const emit = defineEmits(['click'])

</script>
<template>
  <div>
    <button v-if="props.isCustom" @click="() => emit('click',props.link)" class="w-full">
      <slot />
    </button>
    <a v-else :href="props.link" target="_blank" class="no-underline">
      <slot />
    </a>
  </div>
</template>