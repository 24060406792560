<template>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>404 - Not Found</h1>
          <div>
            <router-link to="/dashboard"><button class="btn btn-primary">Dashboard</button></router-link>
            
          </div>
        </div>
      </div>
    </div>
  </template>
  
 <script >

    export default {
        name: 'NotFoundPage',
    }
  
 </script>